import { Injectable } from '@angular/core';
import { Region } from '../../models/region/region.model';
import { GenericService } from '../../services/generic/generic.service';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<Region>> {
    let serviceUrl = Config.regionGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

}
