import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';
import { GenericService } from '../../services/generic/generic.service';
import { CancelPolicyRequestModel, CancelPolicyResponseModel } from '../../models/cancel-policy-model/cancel-policy-model';



@Injectable({
  providedIn: 'root'
})
export class CancelPolicyService {


  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<CancelPolicyResponseModel>> {
    let serviceUrl = Config.cancelPolicyGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  create(cancelationPolicy: any): Observable<boolean> {
    let serviceUrl = Config.cancelationPolicyCreate;
    return this.genericService.postService(cancelationPolicy, serviceUrl, true);
  }

  getAllByCardId(tariffCardId: number): Observable<Array<any>> {
    let serviceUrl = Config.cancelPolicyGetAllByCardId + '?TariffCardId=' + tariffCardId;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllByCardItemId(cardId: number, tariffCardItemId: number): Observable<Array<any>> {
    let serviceUrl = Config.cancelPolicyGetAllByCardItemId + '?TariffCardId=' + cardId + '&TariffCardItemId=' + tariffCardItemId;
    return this.genericService.getService(serviceUrl, true);
  }

  delete(id: number): Observable<boolean> {
    let serviceUrl = Config.cancelPolicyDelete + '?id=' + id;
    return this.genericService.getService(serviceUrl, true);
  }

}