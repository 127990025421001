import { type } from 'jquery';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config, ServiceRoutes } from '../../models/config/config.model';
import { VehicleMasterByTypes } from '../../models/vehicle-master/vehicle-master-by-types.model';
import { VehicleMasterView } from '../../models/vehicle-master/vehicle-master-view.model';
import { VehicleMaster } from '../../models/vehicle-master/vehicle-master.model';
import { GenericService } from '../../services/generic/generic.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleMasterService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<VehicleMasterView>> {
    let serviceUrl = Config.vehicleMasterGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  getVehicleByTypes(types: any) : Observable<Array<VehicleMasterByTypes>> {
    const code = types;
    //var code :Array<string> = types;
    var str = code.join(",");
    const serviceUrl = ServiceRoutes.addOnService.getVehicleMasterByTypes.url + ServiceRoutes.addOnService.getVehicleMasterByTypes.params.type + str;
    //console.log(JSON.stringify(serviceUrl));
    return this.genericService.getService(serviceUrl,true);
    
  }

  create(vehicleMaster: VehicleMaster): Observable<boolean> {
    let serviceUrl = Config.vehicleMasterCreate;
    return this.genericService.postService(vehicleMaster, serviceUrl, true);
  }

  update(vehicleMaster: VehicleMaster): Observable<boolean> {
    let serviceUrl = Config.vehicleMasterUpdate;
    return this.genericService.postService(vehicleMaster, serviceUrl, true);
  }

}
