import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Badge } from '../../models/badge/badge.model';
import { ServiceRoutes } from '../../models/config/config.model';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  constructor(private _genericService: GenericService) { }

  getAll(): Observable<Array<Badge>> {
    const serviceUrl = ServiceRoutes.badge.getAll;
    return this._genericService.getService(serviceUrl, true);
  }

}
