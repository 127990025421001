import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleCategory } from '../../models/vehicle-category/vehicle-category.model';
import { Config, ServiceRoutes } from '../../models/config/config.model';
import { GenericService } from '../../services/generic/generic.service';
import { VehicleOption } from '../../models/vehicle-category/vehicle-option.model';
import { AllVehicleCategory } from '../../models/vehicle-category/all-vehicle-category.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleCategoryService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<VehicleCategory>> {

    let serviceUrl = Config.vehicleCategoryGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  vehicleCategory(): Observable<Array<AllVehicleCategory>>{
    let serviceUrl = Config.vehicleCategory;
    return this.genericService.getService(serviceUrl, true);
  }

  //get all vehicle category
  getAllVehicleCategory(): Observable<Array<VehicleOption>> {
    const serviceUrl = ServiceRoutes.equipments.vehicleCategory.url;
    return this.genericService.getService(serviceUrl,true);
  }

}
