import { Injectable } from '@angular/core';
import { Config } from '../../models/config/config.model';
import { Observable } from 'rxjs';
import { GenericService } from '../../services/generic/generic.service';
import { BookingModule } from '../../models/booking-module/booking-module.model';

@Injectable({
  providedIn: 'root'
})
export class BookingmoduleService {

  constructor(private genericService: GenericService) { }

  getAll(agencyTypeId: number): Observable<Array<BookingModule>> {
    let serviceUrl = Config.bookingModuleServiceGetAll + '?agencyTypeId=' + agencyTypeId;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllBookingModule(): Observable<Array<BookingModule>> {
    let serviceUrl = Config.bookingModuleServiceGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

}
