import { Injectable } from '@angular/core';
import { Country } from '../../models/country/country.model';
import { GenericService } from '../../services/generic/generic.service';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';
import { City } from '../../models/country/city.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<Country>> {
    let serviceUrl = Config.countryGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllCitiesByLocationType(type: number): Observable<Array<City>> {
    let serviceUrl = Config.cityGetAllByLocationType + '?id=' + type;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllCitiesByCountry(countryCode: string): Observable<Array<City>> {
    let serviceUrl = Config.cityGetAll + '?countrycode=' + countryCode;
    return this.genericService.getService(serviceUrl, true);
  }

}
