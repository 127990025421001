import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';
import { DeliveryThreshold } from '../../models/delivery-threshold/delivery-threshold.model';
import { DeliveryThresholdView } from '../../models/delivery-threshold/delivery-threshold-view.model';

import { GenericService } from '../../services/generic/generic.service';
import { SelfDriveDeliveryThreshold } from '../../views/self-drive/models/delivery-threshold.model';
import { SelfDriveDeliveryThresholdView } from '../../views/self-drive/models/delivery-threshold-view.model';
import { EquipmentsDeliveryThresholdView } from '../../views/self-drive-equipments/models/delivery-threshold-view.model';
import { EquipmentsDeliveryThreshold } from '../../views/self-drive-equipments/models/delivery-threshold.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryThresholdService {

  constructor(private genericService: GenericService) { }

  create(deliveryThreshold: DeliveryThreshold): Observable<boolean> {
    let serviceUrl = Config.deliveryThresholdInsert;
    return this.genericService.postService(deliveryThreshold, serviceUrl, true);
  }
  update(deliveryThreshold: DeliveryThreshold): Observable<boolean> {
    let serviceUrl = Config.deliveryThresholdUpdate;
    return this.genericService.postService(deliveryThreshold, serviceUrl, true);
  }

  getAll(): Observable<Array<DeliveryThresholdView>> {
    let serviceUrl = Config.deliveryThresholdGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllSelfdriveThreshold(): Observable<Array<SelfDriveDeliveryThresholdView>> {
    let serviceUrl = Config.deliveryThresholdSelfdriveGetAll;
    return this.genericService.getService(serviceUrl, true);
  }  

  getSelfdriveThresholdById(id: number): Observable<SelfDriveDeliveryThreshold> {
    let serviceUrl = Config.deliveryThresholdSelfdriveById + '?id=' + id;
    return this.genericService.getService(serviceUrl, true);
  }

  createSelfdriveThreshold(deliveryThreshold: SelfDriveDeliveryThreshold): Observable<boolean>{
    let serviceUrl = Config.deliveryThresholdSelfdriveInsert;
    return this.genericService.postService(deliveryThreshold, serviceUrl, true);
  }


  getAllEquipmentsThreshold(): Observable<Array<EquipmentsDeliveryThresholdView>> {
    let serviceUrl = Config.deliveryThresholdEquipmentsGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  getEquipmentsThresholdById(id: number): Observable<EquipmentsDeliveryThreshold> {
    let serviceUrl = Config.deliveryThresholdEquipmentsById + '?id=' + id;
    return this.genericService.getService(serviceUrl, true);
  }

  createEquipmentsThreshold(deliveryThreshold: EquipmentsDeliveryThreshold): Observable<boolean>{
    let serviceUrl = Config.deliveryThresholdEquipmentsInsert;
    return this.genericService.postService(deliveryThreshold, serviceUrl, true);
  }

}
