import { UserTypes } from './../../models/user/UserTypes.model';
import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';

@Injectable({
  providedIn: 'root'
})
export class UsertypesService {

  constructor(private genericService: GenericService) { }


  create(usertypes): Observable<boolean> {
    let serviceUrl = Config.userTypeServiceCreate;
    return this.genericService.postService(usertypes, serviceUrl, true);
  }

  getAll(): Observable<Array<UserTypes>> {
    let serviceUrl = Config.userTypeServiceGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  update(usertypes: UserTypes): Observable<boolean> {
    let serviceUrl = Config.userTypeServiceUpdate;
    return this.genericService.postService(usertypes, serviceUrl, true);
  }
}
