import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../models/config/config.model';
import { Currency } from '../../models/currency/currency.model';
import { GenericService } from '../../services/generic/generic.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private genericService: GenericService) { }

  getAll(): Observable<Array<Currency>> {
    let serviceUrl = Config.currencyGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

}
