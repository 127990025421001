import { Injectable } from '@angular/core';
import { add } from 'ngx-bootstrap/chronos';
import { Observable } from 'rxjs';
import { AddOnMaster } from '../../models/add-on-master/add-on-master.model';
import { CalculationModel } from '../../models/calculation/calculation.model';
import { AddonServiceModel, AddonserviceModelRequest, AddonServiceModelResponse, EditAddonServiceResponse } from '../../models/add-on-master/addon-service.model';

import { Config, ServiceRoutes } from '../../models/config/config.model';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root'
})
export class AddOnMasterService {

  id: number;

  constructor(private genericService: GenericService) { }

  create(addOnMaster: AddOnMaster): Observable<boolean> {
    let serviceUrl = Config.addOnMasterCreateAll;
    return this.genericService.postService(addOnMaster, serviceUrl, true);
  }
  updateAddonMaster(addOnMaster: AddOnMaster): Observable<boolean> {
    let serviceUrl = Config.updateAddonMaster;
    return this.genericService.postService(addOnMaster, serviceUrl, true);
  }

  getAllAddOnMaster(): Observable<Array<AddOnMaster>> {
    let serviceUrl = Config.addOnMasterGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllAddOnService(): Observable<Array<AddonServiceModelResponse>> {
    let serviceUrl = Config.addonServiceGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  update(addOnMaster: AddOnMaster): Observable<boolean> {
    let serviceUrl = '?';
    return this.genericService.getService(serviceUrl, true);
  }

  delete(id: number): Observable<boolean>{
    const serviceUrl = ServiceRoutes.addOnMaster.delete.url + ServiceRoutes.addOnMaster.delete.params.id + id;
    return this.genericService.deleteService(serviceUrl,true);
  }

  //////////////////////////////////////Addon Servicse//////////////

  getAllFareTypes(): Observable<any> {
    const fareTypeUrl = Config.fareTypeGetAll;
    return this.genericService.getService(fareTypeUrl, true);
  }

  getAllChargeTypes(): Observable<any> {
    const fareTypeUrl = Config.chargeTypeGetAll;
    return this.genericService.getService(fareTypeUrl, true);
  }

  getAllServices(id): Observable<EditAddonServiceResponse> {
    let serviceUrl = Config.addOnMasterServicesGetAll + id;
    return this.genericService.getService(serviceUrl, true);
  }

  getAllService(): Observable<Array<AddonServiceModelResponse>> {
    let serviceUrl = Config.addonServiceGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

  createAddonService(addOnMasterService: AddonserviceModelRequest): Observable<boolean> {
    let serviceUrl = Config.addonServiceCreateAll;
    return this.genericService.postService(addOnMasterService, serviceUrl, true);
  }
  updateAddonService(addOnMasterService: AddonserviceModelRequest): Observable<boolean> {
    let serviceUrl = Config.updateAddonService;
    return this.genericService.postService(addOnMasterService, serviceUrl, true);
  }

  getAllCalculationTypes(): Observable<Array<CalculationModel>> {
    let serviceUrl = Config.calculationServiceGetAll;
    return this.genericService.getService(serviceUrl, true);
  }

}
